import { Controller } from 'stimulus';
import { ConfirmationModal } from '../utils/confirmation_modal';
export default class extends Controller {
  static targets = ['middleName', 'noMiddleName', 'ssn', 'internationalNoSsn']

  connect () {
    this.confirmModal = new ConfirmationModal({ text: 'Are you ready to submit? Don’t worry, you can still make changes later if needed.' })
  }

  toggleNoMiddleName() {
    if(this.noMiddleNameTarget.checked) {
      this.middleNameTarget.setAttribute('disabled', true)
    }
    else {
      this.middleNameTarget.removeAttribute('disabled')
    }
  }

  toggleNoSsn() {
    if (this.internationalNoSsnTarget.checked) {
      this.setInternationalSsn()
      this.ssnTarget.setAttribute('disabled', true)
    } else {
      this.ssnTarget.value = ''
      this.ssnTarget.removeAttribute('disabled')
    }
  }

  setInternationalSsn() {
    this.ssnTarget.value = this.data.get('internationalSsn')
  }

  onSubmitForm (event) {
    var _event = event;
    event.preventDefault();

    this.confirmModal.show().then((result) => {
      if (result.value) { 
        _event.submitter.closest('form').submit()
      }
    })
  }
}