import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { chartData: Object }

  connect() {
    $(this.element).highcharts(this.chartOptions)
  }

  get chartOptions() {
    return {
      xAxis: { categories: this.chartDataValue.categories },
      series: [{
        name: this.chartDataValue.name,
        type: this.chartDataValue.type,
        data: this.chartDataValue.data,
        dataLabels: {
          enabled: true,
          color: this.chartDataValue.color
        }
      }]
    }
  }
}