import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  static targets = ['searchItemWrapper']

  onSearchList(e) {
    const q = e.currentTarget.value;
    if (q) { 
      this.searchMatchList(q.toLowerCase());
    } else {
      this.resetMatchList();
    }
  }

  searchMatchList(q) {
    this.searchItemWrapperTargets.forEach((wrapper) => {
      const items = wrapper.querySelectorAll("li")
      items.forEach((item) => {
        const text = item.innerText.toLowerCase();

        if (text.indexOf(q) > -1) {
          item.style.display = '';
        } else {
          item.style.display = 'none';
        }
      });
    });
  }

  resetMatchList() {
    this.searchItemWrapperTargets.forEach((wrapper) => {
      const items = wrapper.querySelectorAll("li")
      items.forEach((item) => {
        item.style.display = '';
      });
    });
  }
}