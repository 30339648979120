import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['linkBy', 'companyLink']

  connect() {
    this.setCompanyLinkField()
  }

  setCompanyLinkField() {
    if (this.linkByTarget.value == 'company_upload') {
      this.companyLinkTarget.hidden = false
      this.companyLinkTarget.querySelector('input').type = 'url'
    } else {
      this.companyLinkTarget.hidden = true
      this.companyLinkTarget.querySelector('input').type = 'text'
    }
  }
}