// https://github.com/sweetalert2/sweetalert2/tree/v7.18.0?tab=readme-ov-file
export class ConfirmationModal {
  constructor(options) {
    this.configs = Object.assign({}, ConfirmationModal.defaultConfigs, options);
  }

  static defaultConfigs = {
    title: '',
    text: '',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
    showCancelButton: true,
    padding: '1em 2em',
    width: '30em',
    buttonsStyling: false,
    confirmButtonClass: 'swal2-confirm inline-flex items-center rounded-md bg-green-100 px-3 py-2 font-medium text-green-700',
    cancelButtonClass: 'swal2-confirm inline-flex items-center rounded-md bg-red-100 px-3 py-2 font-medium text-red-700'
  }

  show() {
    return Swal(this.configs)
  }
}