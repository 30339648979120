import ApplicationController from "./application_controller";
import SignaturePad from 'signature_pad';

export default class extends ApplicationController {
  static targets = ['canvas', 'typedInput', 'currentSignature', 'acceptButton', 'fontPath']

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget)
    this.canvasContext = this.canvasTarget.getContext('2d')
    this.font = new FontFace('CanvasFont', `url(${this.fontPathTarget.value})`)
    this.font.load().then((font) => {
      document.fonts.add(font)
      this.canvasContext.font = `48px ${font.family}`
    }).catch(() => {
      // fallback option
      this.canvasContext.font = '48px sans-serif'
    })

    this.canvasContext.textBaseline = 'middle'
    this.acceptButtonTarget.disabled = false
    this.originalAcceptButtonText = this.acceptButtonTarget.innerHTML

    if (this.hasCurrentSignature()) { this.loadSignature(this.currentSignatureTarget.value) }

    this.fitToContainer()
  }

  fitToContainer() {
    const canvas = this.canvasTarget

    if ((canvas.offsetWidth === 0 || canvas.offsetHeight === 0) && window.innerWidth > 500) { return }

    canvas.style.width = '100%';
    canvas.style.height = '100%';
    // ...then set the internal size to match

    if (window.innerWidth < 500) {
      canvas.width = window.innerWidth * 0.8;
      canvas.height = 200;
    } else {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    }
  }

  _getImageDimensions(imageDataUri) {
    const image = new Image()
    image.src = imageDataUri

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const ratio = image.width / image.height
        resolve({ width: image.width, height: image.height, ratio: ratio })
      }
    })
  }

  async loadSignature(imageDataUri) {
    const dimensions = await this._getImageDimensions(imageDataUri)
    this.signaturePad.fromDataURL(imageDataUri, { width: dimensions.width, height: dimensions.height, ratio: dimensions.ratio })
  }

  clear(event) {
    event.preventDefault()

    this.signaturePad.clear()

    this.typedInputTarget.value = null
    this.acceptButtonTarget.innerHTML = this.originalAcceptButtonText
    this.acceptButtonTarget.disabled = false

    window.dispatchEvent(this.stateChangedEvent('notReady'))
  }

  typeToSignature(event) {
    const width = this.canvasTarget.width
    const height = this.canvasTarget.height

    this.canvasContext.clearRect(0, 0, width, height)
    this.canvasContext.fillText(event.target.value, 5, (height / 2), width - 10)
  }

  acceptSignature(event) {
    event.preventDefault()

    if (this.unsigned()) {
      window.dispatchEvent(this.stateChangedEvent('notReady'))
      return
    }

    if (this.hasCurrentSignatureTarget) {
      this.currentSignatureTarget.value = this.signaturePad.toDataURL()
    }

    this.acceptButtonTarget.innerHTML = 'Accepted Signature'
    this.acceptButtonTarget.disabled = true

    window.dispatchEvent(this.stateChangedEvent('ready'))
  }

  hasCurrentSignature() {
    return !(_.isEmpty(this.currentSignatureTarget.value))
  }

  stateChangedEvent(state) {
    const detail = { state: state, type: 'electronic' }

    return new CustomEvent('signatureStateChanged', { detail: detail })
  }

  unsigned() {
    const canvas = this.canvasTarget
    const pixelBuffer = new Uint32Array(
      this.canvasContext.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    return !pixelBuffer.some(color => color !== 0);
  }
}